import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import SecondaryLanding from "../../../../layouts/secondary-landing";
import HeroChevron from "../../../../components/hero/hero-chevron";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import SEO from "../../../../components/seo/seo";
import FaqAccordion from "../../../../components/faq/faq-accordion";
import WafdBankBenefits from "../../../../components/wafd-bank-benefits/wafd-bank-benefits";
import Button from "../../../../components/custom-widgets/button";
import MortgageCalculator from "../../../../components/calculators/mortgage-calculator/calculator";
import List from "../../../../components/custom-widgets/list";
import ServiceStatesLinks from "../../../../components/service-states/service-states-links";

import getHeroImgVariables from "../../../../helpers/getHeroImgVariables";

const LotLoans = () => {
  const title = "Calcule los pagos de sus préstamos para lotes y terrenos",
    description =
      "Descubra el mejor banco para financiar préstamos sobre terrenos que le ayudarán a construir la casa de sus sueños. Consulte la calculadora de préstamos inmobiliarios de WaFd Bank y las tasas actuales.";
  const pageData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/land-loans/hero-lot-loans-02-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/land-loans/hero-lot-loans-02-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/home-loans/land-loans/hero-lot-loans-02-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/home-loans/land-loans/hero-lot-loans-02-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/home-loans/land-loans/hero-lot-loans-02-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/land-loans/hero-lot-loans-02-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/land-loans/hero-lot-loans-02-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
      CardImage1: file(relativePath: { eq: "cards/thumbnail-buying-land-build-house-071023.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      CardImage2: file(relativePath: { eq: "cards/thumbnail-funding-dream-home-082823.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      CardImage3: file(relativePath: { eq: "cards/thumbnail-construction-082823.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const heroChevronData = {
    id: "land-loans-hero",
    ...getHeroImgVariables(pageData),
    altText: "Father hugging his kids in an open lot",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "¡Pon la casa de tus sueños justo donde la quieres!"
          }
        },
        {
          id: 2,
          button: {
            id: "state-lot-loans-hero-cta",
            text: "Calculadora de Préstamos",
            url: "#mortgage-calculator-section",
            class: "btn-white",
            icon: {
              position: "right",
              lib: "fas",
              name: "calculator",
              class: "float-right mt-1 ml-3"
            }
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/es",
      title: "Banca Personal"
    },
    {
      id: 2,
      url: "/es/banca-personal/prestamos-hipotecarios",
      title: "Préstamos Hipotecarios"
    },
    {
      id: 3,
      active: true,
      title: "Préstamos para Lotes y Terrenos"
    }
  ];

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/banca-personal/prestamos-hipotecarios/prestamos-para-lotes-y-terrenos"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-land-loans-12-22-2022.jpg"
      }
    ]
  };

  const AVG_PRICE = 350000;
  const calculatorData = {
    title: "Calculadora de préstamos para terrenos",
    loanType: "Lot Loan",
    loanTerm: "20 Year Fixed",
    interestRate: 0,
    estimatedPayment: null,
    purchasePrice: AVG_PRICE,
    downPaymentPercent: 30,
    downPaymentAmount: AVG_PRICE * 0.3,
    loanAmount: AVG_PRICE - AVG_PRICE * 0.3,
    isSpanish: true,
    disclaimersSectionClass: "pt-0"
  };

  const faqAccordionData = {
    id: "spanish-land-loans-faq-accordion",
    title: "Preguntas frecuentes",
    seeMoreFaqsText: "Ver mas preguntas frecuentes",
    seeMoreFaqsLink: "/es/banca-personal/prestamos-hipotecarios/preguntas-frecuentes",
    seeMoreFaqsId: "spanish-land-loans-see-more-faqs-link",
    faqCategoryNames: ["Spanish Land Loans"]
  };

  const landLoanChecklistData = {
    greenCircleCheckmark: true,
    accountName: "land-loans-checklist",
    items: [
      {
        id: 1,
        text: "70% LTV tierra mejorado."
      },
      {
        id: 2,
        text: "Disponible en montos de préstamo de hasta $700,000."
      },
      {
        id: 3,
        text: "Opciones disponibles a corto y largo plazo."
      }
    ]
  };

  return (
    <SecondaryLanding showMortgageNotification={true}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />

      <section className="border-bottom-4 border-success pb-0">
        <div className="container">
          <h1>Préstamos para lotes y terrenos</h1>
          <div className="row my-4">
            <div className="col-md-6">
              <h3>
                Con un préstamo para lote de WaFd Bank, obtenga la financiación que necesita para comprar el lugar
                perfecto para su nueva casa, ya sea que la construya en dos años o veinte.
              </h3>
              <h5 className="text-success">
                Su oficial de préstamos local puede ayudarle a comenzar. WaFd Bank lleva más de 100 años ayudando a las
                personas a construir la casa de sus sueños.
              </h5>
              <div className="row">
                <div className="col-sm-6 col-md-12 col-lg-6 mb-3">
                  <Button
                    class="btn-link w-100"
                    url="/es/banca-personal/tasas-de-interes-hipotecarias-actuales"
                    text="Ver Tarifas de Hoy"
                    id="rates-btn"
                    showIcon={false}
                  />
                </div>
                <div className="col-sm-6 col-md-12 col-lg-6 mb-3">
                  <Button
                    class="btn-primary w-100"
                    url="/es/banca-personal/contacte-a-un-asesor-hipotecario"
                    text="Contacta a un Experto"
                    id="find-loan-officer-btn"
                    showIcon={false}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <h5>Lista de verificación de préstamos inmobiliarios</h5>
              <List {...landLoanChecklistData} />
              <p>
                Considere un préstamo de lote a corto plazo de WaFd y reciba un descuento en los costos de cierre cuando
                financie la construcción de su casa personalizada con nosotros dentro de dos años. También ofrecemos un
                préstamo de lote a plazo de 20 años para planes a largo plazo. Presente su solicitud en línea, donde
                podrá cargar sus documentos y recibir actualizaciones de estado en nuestro portal seguro.
              </p>
            </div>
          </div>
        </div>
      </section>
      <MortgageCalculator {...calculatorData} />
      <section className="bg-info text-white">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <StaticImage
                src="../../../../images/cards/personalization/thumbnail-closing-costs-03152023-730.jpg"
                alt="Young couple moving in new house, holding keys"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="col-md-6">
              <h3>Ahorre hasta $600 en costos de cierre con una cuenta corriente de WaFd Bank*</h3>
              <Button
                class="btn-white w-100 w-sm-auto"
                url="/es/banca-personal/prestamos-hipotecarios/ahorre-en-costos-de-cierre"
                text="Averigue Como!"
                id="closing-cost-stripe-cta"
                showIcon={false}
                containerClass="mb-4"
              />
              <p className="text-sm" id="closing-cost-disclaimer">
                * Para calificar para el descuento máximo de $600, antes de preparar los documentos de cierre, debe (1)
                tener o abrir la cuenta de cheques WaFd, (2) registrarse para EZ-Pay desde la nueva cuenta y (3)
                registrarse para el depósito directo de al menos una forma de fuente de ingresos recurrente mensual.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="bg-light">
        <WafdBankBenefits isSpanish={true} />
      </div>
      <FaqAccordion {...faqAccordionData} />
      <section className="container pt-0">
        <div className="row justify-content-center text-center mb-4">
          <div className="col-lg-8">
            <h2 className="text-success">Quieres más ayuda? Ven a vernos</h2>
            <p>
              Si ha investigado todo pero no está seguro de si un préstamo para lotes tiene sentido, simplemente
              visítenos. Estamos cerca y nos sentaremos contigo y responderemos cualquier pregunta que puedas tener.
            </p>
            <ServiceStatesLinks />
          </div>
        </div>
        <p className="text-muted text-sm" id="land-loans-disclaimer">
          * Para calificar para el descuento máximo de $600, antes de preparar los documentos de cierre, debe (1) tener
          o abrir la cuenta corriente WaFd, (2) registrarse para EZ-Pay desde la nueva cuenta corriente y (3)
          registrarse para el depósito directo de al menos una forma de fuente de ingresos recurrente mensual.
        </p>
      </section>
    </SecondaryLanding>
  );
};

export default LotLoans;
